.content {
  height: 100vh;
  grid-template-rows: 30vh 70vh;
  grid-template-columns: [flag-cl-start balloon-l-start] 1fr[flag-cl-end balloon-l-end flag-l-start frame-start] 1fr[flag-l-end flag-r-start] 1fr[flag-r-end flag-cr-start balloon-r-start frame-end] 1fr[flag-cr-end balloon-r-end];
  display: grid;
  position: absolute;
  overflow: hidden;
}

.content > * {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .content {
    grid-template-columns: [flag-l-start balloon-l-start frame-start] 1fr[balloon-l-end flag-l-end flag-r-start balloon-r-start] 1fr[flag-r-end balloon-r-end frame-end];
  }
}

.frame {
  opacity: 0;
  text-align: center;
  flex-direction: column;
  grid-area: 2 / frame-start / 3 / frame-end;
  justify-content: space-evenly;
  align-items: center;
  display: none;
}

.page-404 {
  text-align: center;
  background-color: #413f42;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

@media (min-width: 1200px) {
  html {
    font-size: 70%;
  }
}

body {
  color: #fff;
  font-family: Courgette, cursive;
}

.balloon {
  width: 130%;
  grid-row: 1 / -1;
}

.balloon--left {
  grid-column: balloon-l-start / balloon-l-end;
  animation: balloon-move 4s linear infinite;
}

@media (max-width: 800px) {
  .balloon--left {
    margin-left: -25%;
  }
}

@media (max-width: 500px) {
  .balloon--left {
    margin-left: -40%;
  }
}

.balloon--right {
  grid-column: balloon-r-start / balloon-r-end;
  margin-left: -20%;
  animation: balloon-move 4s linear infinite reverse;
}

@media (max-width: 800px) {
  .balloon--right {
    margin-left: 25%;
  }

  .balloon {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .balloon {
    width: 120%;
  }
}

.flag {
  z-index: 0;
  grid-row: 1 / 2;
}

.flag--c-left {
  grid-column: flag-cl-start / flag-cl-end;
}

@media (max-width: 800px) {
  .flag--c-left {
    display: none;
  }
}

.flag--c-right {
  grid-column: flag-cr-start / flag-cr-end;
}

@media (max-width: 800px) {
  .flag--c-right {
    display: none;
  }
}

.flag--left {
  grid-column: flag-l-start / flag-l-end;
}

.flag--right {
  grid-column: flag-r-start / flag-r-end;
}

@media (max-width: 800px) {
  .flag {
    margin-top: 20%;
    transform: scale(1.2);
  }
}

.img-back {
  height: 20rem;
  width: 20rem;
  background-color: #ffa8dc;
  border-radius: 50%;
  position: relative;
}

@media (max-width: 800px) {
  .img-back {
    height: 35rem;
    width: 35rem;
  }
}

@media (max-width: 500px) {
  .img-back {
    height: 20rem;
    width: 20rem;
  }
}

.bd-pic {
  height: 83.3333%;
  width: 83.3333%;
  background-image: url("pic.5c387d32.jpeg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-style: dotted;
  border-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.cap {
  height: 33.3333%;
  position: absolute;
  top: -15%;
  left: 50%;
  transform: translate(0);
}

.confetti {
  height: 150%;
  width: 150%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cake {
  height: 70%;
  width: 70%;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.HBD-text {
  width: 90%;
}

.HBD {
  padding: 0;
  font-size: 2.4rem;
}

@media (max-width: 800px) {
  .HBD {
    font-size: 3.4rem;
  }
}

@media (max-width: 500px) {
  .HBD {
    font-size: 3rem;
  }
}

.nickname {
  padding: 0;
  font-size: 3.2rem;
}

@media (max-width: 800px) {
  .nickname {
    font-size: 4.2rem;
  }
}

@media (max-width: 500px) {
  .nickname {
    font-size: 3.8rem;
  }
}

.scroll {
  height: 70%;
  width: 80%;
  font-size: 2.4rem;
  position: relative;
  overflow-y: hidden;
}

@media (max-width: 800px) {
  .scroll {
    height: 60%;
  }
}

@media (max-width: 500px) {
  .scroll {
    height: 80%;
    font-size: 2.2rem;
  }
}

.text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0);
}

.btn {
  height: 15rem;
  width: 15rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  animation: heart-beat 2s linear infinite alternate;
  display: none;
  position: absolute;
  top: 60%;
  left: 50%;
  overflow: hidden;
  transform: translate(0, -50%);
}

.btn-ref {
  text-align: center;
  color: #fff;
  font-size: 1.8rem;
  display: none;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%);
}

.switch {
  background-image: url("bulb.41497508.svg");
}

.gift {
  background-image: url("gift.8fae589d.svg");
}

.door-in, .door-out {
  background-image: url("door.b065644e.svg");
}

.darkroom, .flash, .giftroom, .empty-room, .hallway {
  text-align: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.darkroom {
  background-color: #000;
}

.flash {
  background-color: #fff;
}

.giftroom, .empty-room {
  background-color: #141852;
}

.hallway {
  background-image: radial-gradient(rgba(0, 0, 0, 0), #000), url("Hallway.ae27ca8f.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.empty-room, .giftroom, .content {
  background-image: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .65)), url("bedroom.9951b417.svg");
  background-position: 70%;
  background-repeat: no-repeat;
  background-size: cover;
}

.bb-text, .gift-text, .hall-text, .room-text {
  text-align: center;
  width: 80vw;
  opacity: 0;
  font-size: 3.2rem;
  display: inline-block;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.velas {
  width: 1.5%;
  height: 12%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #fff;
  border-radius: .625rem;
  position: absolute;
  top: 70%;
  left: 50%;
}

.velas:after, .velas:before {
  content: "";
  width: 100%;
  height: 5%;
  background: rgba(255, 0, 0, .4);
  position: absolute;
}

.velas:after {
  top: 25%;
  left: 0;
}

.velas:before {
  top: 45%;
  left: 0;
}

.fuego {
  width: 100%;
  height: 40%;
  border-radius: 100%;
  margin-left: -2.6px;
  position: absolute;
  top: -50%;
  left: 60%;
}

.fuego:nth-child(1) {
  animation: fuego 2s 6.5s infinite;
}

.fuego:nth-child(2) {
  animation: fuego 1.5s 6.5s infinite;
}

.fuego:nth-child(3) {
  animation: fuego 1s 6.5s infinite;
}

.fuego:nth-child(4) {
  animation: fuego .5s 6.5s infinite;
}

.fuego:nth-child(5) {
  animation: fuego .2s 6.5s infinite;
}

@keyframes fuego {
  0%, 100% {
    box-shadow: 0 0 250% 62.5% rgba(248, 233, 209, .2);
    background: rgba(254, 248, 97, .5);
    transform: scale(1);
  }

  50% {
    box-shadow: 0 0 250% 125% rgba(248, 233, 209, .2);
    background: rgba(255, 50, 0, .1);
    transform: scale(0);
  }
}

@keyframes in {
  to {
    transform: translateY(0);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes read {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes heart-beat {
  0% {
    transform: translate(-50%, -50%)scale(1);
  }

  50% {
    transform: translate(-50%, -50%)scale(1.25);
  }

  100% {
    transform: translate(-50%, -50%)scale(1);
  }
}

@keyframes balloon-move {
  0% {
    transform: translateY(0) rotate(0);
  }

  25% {
    transform: translateY(5%)rotate(3deg);
  }

  50% {
    transform: translateY(0) rotate(0);
  }

  75% {
    transform: translateY(-5%)rotate(-3deg);
  }

  100% {
    transform: translateY(0) rotate(0);
  }
}

@keyframes move-up {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.hidden {
  display: none;
}

.fade-in {
  animation: fade 2s ease-in;
}

.appear {
  animation: fade 2s linear reverse;
}

.read {
  animation: read 4s linear;
}

.move-up {
  animation: move-up var(--readTime) linear 3s;
}

/*# sourceMappingURL=index.6970c019.css.map */
